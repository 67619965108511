.header{
	position: fixed;
	top:0;left:0;
	z-index: 99;
	width: 100%;
	pointer-events: none;
	font-size: 14px;
	font-family: corporate_monoregular;
	color: $yellow;
	a {
		color: inherit;
	}
}

.site-nav {
	padding: .6em;
	background: white;
	border-bottom: solid 1px $yellow;
	ul li {
		display: inline-block;
		text-transform: uppercase;
		margin: 0 1.5em;
		letter-spacing: .5px;
		pointer-events: auto;
		line-height: 1;
		@media screen and (max-width: 576px){
			margin: 0 0.1em;
			white-space: nowrap;
		}
		a {
			padding: 1px 3px 2px 3px;
		}
		&.active {
			a {
				background-color: $yellow;
				color: white;
			}
		}
	}
}