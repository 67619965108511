main {
	padding: 4em;
	margin: auto;
	padding-top: 6em;
	padding-bottom: 1em;
	max-width: 1200px;
	@media screen and (max-width:768px){
		padding: 4em 1em;
		padding-bottom: 1em;
	}
}

section {
	margin-bottom: 4em;
}

.intro-text {
	margin-bottom: 2em;
}

.m{
	&-b {
		&-1 {
			margin-bottom: 1em!important;
		}
	}
}