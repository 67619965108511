.footer {
	position: fixed;
	bottom:0;left:0;
	z-index: 99; 
	width: 100%;
	pointer-events: none;
	font-size: 0.636em;
	font-family: corporate_monoregular;
	color: $yellow;
	a {
		color: inherit;
	}
}

#pathNav {
	// padding: 1em;
	background: white;
	border-top: solid 1px $yellow;
	line-height: 1;
	pointer-events: auto;
	display: flex;
	opacity: 0;
	.path-arrow {
		font-size: 1em;
		// padding: 0 0.5em;
		position: relative;
		top: 0em;
	}
}
body.path_mode {
	#pathNav {
		opacity: 1;
	}
}

#pathPaginationPrevious {
	display: block;
	padding: 1em;
	width: 6em;
	border-right: solid 1px $yellow;
	flex: 0 0 6em;
	display: flex;
	align-items: center;
	justify-content: center;
	a.disabled {
		opacity: 0.25;
		pointer-events: none;
	}
}

#pathPaginationNext {
	display: block;
	border-left: solid 1px $yellow;
	padding: 1em;
	width: 6em;
	flex: 0 0 6em;
	display: flex;
	align-items: center;
	justify-content: center;
	a.disabled {
		opacity: 0.25;
		pointer-events: none;
	}
}

#pathWrapper {
	display: flex;
	width: 100%;
}


#pathNavNavigation_Users {
	text-transform: uppercase;
}

#pathCurator {
	margin-right: 2em;
}
#pathList {
	// margin-left: 2em;
	padding: 1em;
	margin: auto;
	line-height: 1.6;
	display: inline-block;
	transform: translateY(2px);
	.path-arrow {
		padding: 0 1em; 
	}
	ul li {
		display: inline-block;
		// margin-right: 2em;
		a {

		}
		a:hover {
			border-bottom: solid 0.1em $yellow;
		}
		span.active {
			// border-bottom: solid 0.1em $yellow;
			background-color: $yellow;
		    color: white;
		    padding: 2px 3px 1px 3px;
		    .path-arrow {
		    	background: transparent;
		    	color: $yellow; 
		    }
		}
	}
}

@media screen and (max-width: 567px){
	#pathList ul li {
		display: none;
	}
	#pathCurator {
		display: block!important;
		margin-right: 0;
	}
}